.PivotTableContainer {
  position: relative;
  overflow: hidden;
  table.dataframe {
    position: relative;
  }
  .sticky {
    position: sticky !important;
    top: 0;
    left: 0;
    background-color: #f9f9f9;
    z-index: 10;
    // border: 1px solid var(--white);
    margin: 0px;
  }
  tr {
    th {
      display: table-cell;
      border-collapse: separate;
      max-width: 275px;
      overflow: hidden;
      word-wrap: break-word;
    }
  }
}
.PivotElementList {
  display: flex;
  gap: var(--sp_lv_2);
  height: fit-content;
  .east-container {
    display: flex;
    align-items: center;
    height: 32px;
    justify-content: space-between;
    width: 110px;
    margin-right: var(--sp_lv_4);

    span:nth-child(1) {
      width: 120px;
    }
  }
  .elements-wrapper {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
    // grid-template-columns: repeat(4, 1fr);
    gap: var(--sp_lv_2);

    div {
      // width: 100%;
    }
  }
}

.PivotElement {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--sp_lv_2);
  background-color: var(--white);
  width: 100%;
  height: 32px;
  width: 220px;
  .name {
    width: 160px;
    .no-border {
      width: 100% !important;
      &-selector {
        padding: 0 var(--sp_lv_2) !important;
        margin: 0 !important;
      }
    }
    .MetricElementComponent {
      width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &.isDragging {
    width: 50px;
  }

  .anticon-close-circle {
    right: var(--sp_lv_2);
  }
}

.MetricElementComponent {
  padding: 0 var(--sp_lv_2);
}

.MetricPopoverModalComponent {
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    align-items: center;
  }
}

.AggregateSelectComponent {
  width: 230px;
}

.PivotTableComponent {
  margin-top: var(--sp_lv_4);
  border-top: 1px solid var(--white);
  table {
    border-collapse: separate !important;
    border-spacing: 0px !important;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    width: 100%;
    background-color: var(--white); // todo: uncomment when sortable = false
    border: 1px solid var(--divider);
  }

  table thead tr {
    border: 1px solid var(--white); // todo: uncomment when sortable = false
    text-align: left;
  }

  table th,
  table td {
    padding: 7px 8px;
    text-align: left;
    border-bottom: 1px solid var(--divider); // todo: uncomment when sortable = false
    border-right: 1px solid var(--divider); // todo: uncomment when sortable = false
  }

  table th {
    text-transform: capitalize;
    min-width: 82px !important;
  }
  table tbody tr {
    border: 1px solid var(--white); // todo: uncomment when sortable = false
  }

  table tbody tr {
    background-color: var(--white);
  }

  table tbody tr:last-of-type {
  }

  table tbody tr.active-row {
    font-weight: bold;
    color: #1a1f29;
  }
  .pivot-timeline-highlight-wrapper {
    background-color: #ffec3d;
  }
  .pivot-table-bold-wrapper {
    font-weight: bold !important;
  }
  .pivot-img-wrapper {
    // position: relative;
    // cursor: pointer;
    // display: block;
    &.show {
      .img-modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background-color: rgba($color: #000000, $alpha: 0.7);
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .pivot-modal-img {
        }
      }
    }
  }

  .pivot-img {
    object-fit: contain;
    width: 34px;
    height: 34px;
    border-radius: 4px;
  }
  .img-modal {
    display: none;
    .close {
      position: absolute;
      right: 32px;
      top: 32px;
      width: 32px;
      height: 32px;
      opacity: 0.7;
    }

    .pivot-modal-img {
      width: 240px;
      height: 240px;
    }
  }
}
